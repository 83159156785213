* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.jumbotron {
  height: 70vh;
  background-repeat: no-repeat;
  background-position: right top;
  padding: 0;
  margin: 0;
  overflow-y: hidden !important;
}

.jumbotronContainer {
  background: #242327;
  color: rgb(221, 218, 218);
  height: 100vh;
  padding: 30;
  margin: 30;
  justify-content: center;
  text-align: center;
  position: relative;
}

.jumbotronContainer h1 {
  position: absolute;
  font-size: 50px;
  color: whitesmoke;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jumbotronDiv {
  position: relative;
  top: 32.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stickyNavbar {
  top: 0;
  position: sticky;
  z-index: 5;
}

.aboutContainer {
  margin: auto;
  padding: 50px;
}

.aboutDiv {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carouselContainer {
  height: auto;
  background-color: rgb(45, 45, 47);
}

.carouselContainer img {
  width: fit-content;
  margin: auto;
  justify-content: center;
  text-align: center;
}

.resumeContainer {
  height: 50vh;
  background-color: lightblue;
}

.resumeDiv {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footerContainer {
  height: 5.75vh;
  background-color: lightslategray;
}

.footerDiv {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
}

.projectTitle {
  margin: 15px;
  padding: 15px;
  justify-content: center;
  text-align: center;
}

.projectDiv {
  margin-left: 5%;
  margin-right: 5%;
}

.projectImg {
  width: 100%;
  height: auto;
}

.aws-badges {
  width: 18%;
}

@media (max-width: 576px) {
  .carouselContainer {
    display: none;
  }

  .aboutContainer {
    padding: 15px;
  }

  .jumbotron {
    padding: 40;
  }

  .jumbotronContainer h1 {
    position: absolute;
    font-size: 30px;
    color: whitesmoke;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .projectTitle {
    margin: 15px;
    padding: 15px;
    justify-content: center;
    text-align: center;
    font-size: 25px;
  }

  .aws-badges {
    width: 120px;
  }
}
